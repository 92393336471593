<template>
  <v-container fluid>
    <h2>Teamchallenges</h2>

    <v-card>
      <v-btn
        absolute
        top
        right
        fab
        small
        @click="synctc()"
      >
      <span class="fa-stack fa-1x">
        <i :class="{'fa': true,'fa-sync': true,'fa-stack-2x':true,'fa-spin':sync.tc.in_progress}" />
        <i
          class="fa fa-bars fa-stack-1x"
          style="margin-left:6px;margin-top:6px;color:black;font-size:140%;text-shadow:0 0 2px white;"
        />
      </span>
      </v-btn>

      <v-data-table
        :items="tcs"
        :headers="[
          { text: 'Name', value: 'name', sortable: false },
          { text: 'Modus', value: 'mode', sortable: false },
          { text: 'Gender', value: 'gender', sortable: false },
          { text: 'Teams', value: 'teams', sortable: false, align: 'center' },
          { text: 'IDs', value: 'identifiers', sortable: false, align: 'center' },
          { text: 'Löschen', value: 'delete', sortable: false, align: 'center' }
        ]"
        :mobile-breakpoint="0"
        :items-per-page="-1"
        hide-default-footer
      >
        <template #item.delete="{item}">
          <v-btn
            text
            small
            fab
            @click="del(item._id)"
          >
            <v-icon>
              far fa-trash-alt
            </v-icon>
          </v-btn>
        </template>
        <template #item.identifiers="{item}">
          {{ (item.identifiers || []).length }}
        </template>
        <template #item.teams="{item}">
          {{ (item.teams || []).length }}
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { deleteQuery, updateQuery, useGraphQL } from '@/plugins/graphql'
import { mapGetters } from 'vuex'
import gql from 'graphql-tag'

const query = `
  _id
  identifiers { name value }
  type
  ... on StbLtf2024Tc {
    name mode gender
    teams {
      club { _id name }
      identifiers { name value }
    }
  }
`

export default {
  name: 'edit',

  setup (props, context) {
    return {
      ...useGraphQL(context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    }
  },

  data: () => ({
    EventFind: [],
    sync: {
      tc: {
        in_progress: false
      }
    }
  }),

  computed: {
    ...mapGetters(['loading']),
    tcs () {
      return this.EventFind.slice().sort((a, b) => a.name < b.name ? -1 : 1)
    }
  },

  methods: {
    async synctc () {
      this.sync.tc.in_progress = true

      await this.mutate({
        mutation: gql`
            mutation($parent: UUID!) {
                StbLtf2024TcGymnetImport(parent: $parent) { ${query} }
            }
        `,
        variables: {
          parent: this.id
        }
      })

      this.sync.tc.in_progress = false
    },
    del (id) {
      this.mutate({
        mutation: gql`
          mutation($id: UUID!) { StbLtf2024TcDelete(id: $id) }
        `,
        variables: {
          id
        }
      })
    }
  },

  apollo: {
    EventFind: {
      query: gql`
           query($parent: UUID!) { EventFind(parent: $parent) { ${query} }}
      `,
      variables () {
        return {
          parent: this.id
        }
      },
      subscribeToMore: {
        document: gql`
            subscription($parent: UUID!) { EventCreate(parent: $parent) { ${query} }}
        `,
        variables () {
          return {
            parent: this.id
          }
        },
        updateQuery: updateQuery('EventFind', 'EventCreate')
      }
    },
    $subscribe: {
      EventUpdate: {
        query: gql`
            subscription($parent: UUID!) { EventUpdate(parent: $parent) { ${query} }}
        `,
        variables () {
          return {
            parent: this.id
          }
        }
      },
      EventDelete: {
        query: gql`
            subscription($parent: UUID!) { EventDelete(parent: $parent) }
        `,
        variables () {
          return {
            parent: this.id
          }
        },
        result (id) {
          deleteQuery('EventFind', 'EventDelete', this.EventFind, id)
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
